@font-face {
font-family: '__lato_6f59e2';
src: url(/_next/static/media/045f034fd4b986db-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__lato_6f59e2';
src: url(/_next/static/media/e700db1ec29033c5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__lato_6f59e2';
src: url(/_next/static/media/8d00e7d550d7d3b7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__lato_6f59e2';
src: url(/_next/static/media/8ad7d031250ea800-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__lato_6f59e2';
src: url(/_next/static/media/51581ad1da4e4559-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
}@font-face {font-family: '__lato_Fallback_6f59e2';src: local("Arial");ascent-override: 108.84%;descent-override: 29.81%;line-gap-override: 0.00%;size-adjust: 99.10%
}.__className_6f59e2 {font-family: '__lato_6f59e2', '__lato_Fallback_6f59e2'
}

